import * as React from "react";
import { CInputGroupText } from "@coreui/react";
import { formatPass } from "../../../utils/errors";
import useForm from "../../../hooks/useForm";
import { InputForm as Input } from "../../../components/global-components/controlsForm";
import { useAuth, Status } from "../../../hooks/useAuth";
import { PasswordIcon } from "../../../components/global-components/icons";
import { useHistory } from "react-router-dom";
import AuthLayout from "../../../layout/AuthLayout";
import { changeTitleDOM } from "../../../utils/session";

enum StateParams {
  Loading = "loading",
  Verify = "verify",
  Error = "error",
  Changed = "changed",
}

const Restore = () => {
  const { updatePassword, verifyRecoveryToken, message, status } = useAuth();

  const [statusParam, setStatusParam] = React.useState<StateParams>(
    StateParams.Loading
  );

  const [tokenParam, setTokenParam] = React.useState<string | null>(null);

  const history = useHistory();

  const [showFormatInvalid, setShowFormatInvalid] = React.useState("");

  React.useEffect(() => {
    changeTitleDOM("Recuperación de cuenta");
  }, []);

  React.useEffect(() => {
    async function verifyToken() {
      const token = location?.search.match(
        /^\?token=([A-Za-z0-9\.\-\_]{171,171})$/
      )?.input;
      if (!token) {
        history.replace("/auth/login");
      } else {
        try {
          const formatToken = token.replace("?token=", "");
          const response = await verifyRecoveryToken(formatToken);
          setTimeout(() => {
            if (response?.status === 200) {
              setStatusParam(StateParams.Verify);
              setTokenParam(formatToken);
            } else {
              setStatusParam(StateParams.Error);
            }
          }, 1200);
        } catch (error) {
          setStatusParam(StateParams.Error);
        }
      }
    }
    if (typeof window !== undefined) {
      verifyToken();
    }
  }, [window]);

  const stateSchema = {
    confirmPassword: { value: "", error: "" },
    newPassword: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    confirmPassword: {
      required: true,
      validator: formatPass(),
      nospaces: true,
    },
    newPassword: {
      required: true,
      validator: formatPass(),
      nospaces: true,
    },
  };

  const checkFormat = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.indexOf(" ") >= 0) {
      setShowFormatInvalid("No se permite espacios");
    } else {
      setShowFormatInvalid("");
    }
  };

  const onSubmitForm = async (data: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    if (newPassword && confirmPassword) {
      if (tokenParam) {
        const res = await updatePassword(tokenParam, data);
        if (res?.status === 200) {
          setStatusParam(StateParams.Changed);
        }
      }
    }
  };

  const {
    values: { newPassword, confirmPassword },
    errors: {
      newPassword: newPasswordError,
      confirmPassword: confirmPasswordError,
    },
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  return (
    <AuthLayout>
      <div className="card-body p-4 pb-4">
        {statusParam !== StateParams.Loading ? (
          <>
            {statusParam === StateParams.Verify ? (
              <>
                <p className="w-100 d-flex">
                  <span
                    style={{ height: 3, backgroundColor: "#686868" }}
                    className="d-flex w-100 my-auto"
                  />
                  <span
                    className="text-large-emphasis text-center fw-bolder mx-2 d-flex"
                    style={{ color: "#686868" }}
                  >
                    Contraseña
                  </span>
                  <span
                    style={{ height: 3, backgroundColor: "#686868" }}
                    className="d-flex w-100 my-auto"
                  />
                </p>

                <form onSubmit={handleOnSubmit}>
                  {status === Status.Error ? (
                    <div
                      className="alert alert-danger text-center p-1"
                      role="alert"
                      style={{ fontSize: 14 }}
                    >
                      {message}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="input-group mb-3">
                    <CInputGroupText>
                      <PasswordIcon fill="#000000"></PasswordIcon>
                    </CInputGroupText>
                    <Input
                      type="password"
                      placeholder="Nueva contraseña"
                      disabled={status === Status.Loading}
                      name="newPassword"
                      required
                      value={newPassword || ""}
                      onChange={(e) => {
                        handleOnChange(e);
                        checkFormat(e);
                      }}
                      error={newPasswordError || showFormatInvalid}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <CInputGroupText>
                      <PasswordIcon fill="#000000"></PasswordIcon>
                    </CInputGroupText>
                    <Input
                      type="password"
                      placeholder="Confirmar contraseña"
                      autoComplete="current-password"
                      disabled={status === Status.Loading}
                      required
                      name="confirmPassword"
                      value={confirmPassword || ""}
                      onChange={(e) => {
                        handleOnChange(e);
                      }}
                      error={confirmPasswordError}
                    />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        className="btn text-white w-100 btn-success"
                        type="submit"
                        disabled={
                          disable ||
                          status === Status.Loading ||
                          showFormatInvalid
                            ? true
                            : false
                        }
                      >
                        {status === Status.Loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;Cargando...
                          </>
                        ) : (
                          "Cambiar contraseña"
                        )}
                      </button>
                    </div>
                  </div>
                  <button
                    className="link-dark btn border-none mt-3 mx-auto d-flex mb-3 text-center text-black td-none fw-bold"
                    onClick={() => {
                      history.replace("/auth/login");
                    }}
                  >
                    Regresar a iniciar sesión
                  </button>
                </form>
              </>
            ) : (
              <>
                <p className="mx-2 my-2 text-center p-2 text-black fs-2 fw-bold">
                  {statusParam === StateParams.Error
                    ? "¡Token expirado!"
                    : "¡Contraseña actualizada!"}
                </p>
                <button
                  className="link-dark btn border-none mt-3 mx-auto d-flex mb-3 text-center text-black td-none fw-bold"
                  onClick={() => {
                    history.replace("/auth/login");
                  }}
                >
                  Regresar para iniciar sesión
                </button>
              </>
            )}
          </>
        ) : (
          <p className="mx-2 my-2 text-center p-2 text-dark fs-2 fw-bold">
            Cargando...
          </p>
        )}
      </div>
    </AuthLayout>
  );
};

export default Restore;
