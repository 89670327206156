/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { ExamItem } from "./_children/examByMedicItem";
import { ModalSearchComponent } from "../global-components/modals";

import { changeTitleDOM, generateLastPath } from "../../utils/session";
import { useDoctors, Doctor, Status } from "../../hooks/useDoctors";
import { ROLES } from "../../hooks/types";
import { useAuthContext } from "../../context/AuthContext";
import { LayoutContainer } from "../global-components/layoutContainer";
import { Exam, useExams } from "../../hooks/useExams";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";
import { formatExceedDate, formatExtendNames } from "../../utils/errors";
import { SearchModel } from "../global-components/searchModel";
import { IntervalButton } from "../global-components/globalButtons";
import { setFormatDate } from "../../utils/formats";
import { ExamsByMedicModal } from "./_children/reportsModal";

const ExamsByMedicComponent = () => {
  const { user } = useAuthContext();
  const { doctors, getDoctorsByFilter } = useDoctors();

  const {
    exams,
    getExamsByFilter,
    searchFilter: examsSearchFilter,
    status: examStatus,
    cleanExams,
  } = useExams();

  const [visibleReportModal, setVisibleReportModal] = React.useState(false);

  const [visibleDoctorModal, setVisibleDoctorModal] = React.useState(false);

  const [selectedDoctor, setSelectedDoctor] = React.useState<Doctor | null>(
    null
  );

  const [showDoctorError, setShowDoctorError] = React.useState<boolean>(false);

  const LIMIT_DOCS = 10;

  React.useEffect(() => {
    generateLastPath();
    changeTitleDOM("Reporte por médico");
    getDoctorsByFilter({ filter: "" }, { limit: LIMIT_DOCS });
  }, []);

  React.useEffect(() => {
    if (!selectedDoctor) {
      cleanExams();
    }
  }, [selectedDoctor]);

  const tableExportId = "report-by-medic-table";

  const headers = [
    { label: "Nro. Examen", key: "examNumber" },
    { label: "Nro. Orden", key: "orderNumber" },
    { label: "Paciente", key: "patientName" },
    { label: "Recepcionista", key: "recepcionistName" },
    { label: "Fecha de Emisión", key: "createdDate" },
    { label: "Monto Generado", key: "totalAmount" },
    { label: "Comisión del Médico", key: "commissionDoctor" },
    { label: "Total Ganado", key: "totalGain" },
    { label: "Opciones", key: "options" },
  ];

  const handleSearchDoctors = ({ search }: { search: string | null }) => {
    let filter = "";
    if (search) {
      filter = search;
    }
    getDoctorsByFilter(
      { filter, status: 1 },
      { limit: LIMIT_DOCS, pageSize: 1 }
    );
  };

  const validatorsByDoctor = {
    required: false,
    validator: formatExtendNames(),
    invalidtext: true,
  };

  const validatorsByInterval = {
    required: true,
    validator: formatExceedDate(),
    invalidtext: true,
  };

  const handleSubmit = ({
    startDate,
    endDate,
  }: {
    startDate: string | null;
    endDate: string | null;
  }) => {
    let start = "";
    let end = "";
    if (startDate) {
      start = `${startDate.replace("/", "-")}T00:00:00.0+00:00`;
    }
    if (endDate) {
      end = `${endDate.replace("/", "-")}T23:59:59.999+00:00`;
    }
    getExamsByFilter(
      { startDate: start, endDate: end, doctorId: selectedDoctor?._id },
      { limit: 999, pageSize: 1 }
    );
  };

  const tableGenerateReportId = "report-commision-table";
  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-5 col-md-4 col-xl-3">
          <button
            className="btn btn-success w-100 text-white"
            type="button"
            disabled={exams.length === 0}
            onClick={() => setVisibleReportModal(true)}
          >
            <CIcon icon={cilCloudDownload}></CIcon>&nbsp;&nbsp;Previsualizar
          </button>
        </div>
      </div>
      <LayoutContainer title="EXÁMENES POR MÉDICO">
        <div className="card-body">
          <nav className="navbar navbar-light bg-light d-block d-lg-flex px-3 my-2">
            <div
              className="form-group mt-1 mx-auto mx-lg-0 col-12 col-md-9 col-lg-5 col-xl-3"
              style={{
                maxWidth: 390,
              }}
            >
              <SearchModel
                name="doctor"
                className="d-flex"
                required={true}
                showError={false}
                messageError={"Este campo es requerido."}
                value={
                  `${selectedDoctor?.firstName || ""} ${
                    selectedDoctor?.lastName || ""
                  } ${selectedDoctor?.secondLastName || ""}`.trim() ||
                  "Seleccione un médico..."
                }
                setShowError={setShowDoctorError}
                setShowModal={setVisibleDoctorModal}
              ></SearchModel>
            </div>
            <div className="form-group mt-1 mx-auto mx-lg-0 col-12 col-md-12 col-lg-7 col-xl-6">
              <IntervalButton
                handleSearch={handleSubmit}
                fullText={true}
                textButton="Filtrar"
                maxLength={setFormatDate({
                  order: 1,
                  date: new Date(),
                  separator: "-",
                })}
                validators={validatorsByInterval}
                required={true}
              />
            </div>
          </nav>
          <div
            className="w-100 overflow-auto d-flex flex-column"
            style={{ height: 300 }}
          >
            {!examsSearchFilter?.startDate && !selectedDoctor ? (
              <p className="fw-bold fs-5 text-center my-auto mx-auto">
                Seleccione un médico y el intérvalo de búsqueda...
              </p>
            ) : null}

            {!examsSearchFilter?.startDate && selectedDoctor ? (
              <p className="fw-bold fs-5 text-center my-auto mx-auto">
                Seleccione el intérvalo de búsqueda...
              </p>
            ) : null}
            {examsSearchFilter?.startDate && !selectedDoctor ? (
              <p className="fw-bold fs-5 text-center my-auto mx-auto">
                Seleccione un médico...
              </p>
            ) : null}

            {(examStatus === Status.Ready || examStatus === Status.Updating) &&
            exams.length > 0 ? (
              <table
                className="table table-striped"
                id={tableExportId}
                style={{
                  minWidth: "1300px",
                }}
              >
                <thead>
                  <tr>
                    <th>N°</th>
                    {headers
                      ? headers.map((header) => (
                          <th key={header.label}>{header.label}</th>
                        ))
                      : null}
                    {user?.role?.name === ROLES.MODERATOR ? (
                      <th>Opciones</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {exams.map((exam: Exam, index: number) => (
                    <ExamItem key={index} index={index + 1} exam={exam} />
                  ))}
                </tbody>
              </table>
            ) : (
              <>
                {examsSearchFilter?.startDate &&
                examsSearchFilter?.endDate &&
                selectedDoctor &&
                examStatus == Status.Ready &&
                exams.length === 0 ? (
                  <p className="fw-bold fs-5 text-center my-auto mx-auto">
                    No hay exámenes disponibles
                  </p>
                ) : null}
              </>
            )}
          </div>
        </div>
        <ModalSearchComponent
          onClean={() => {
            setSelectedDoctor(null);
            setShowDoctorError(true);
            setVisibleDoctorModal(false);
          }}
          onSearch={handleSearchDoctors}
          validators={validatorsByDoctor}
          visible={visibleDoctorModal}
          title="Selecciona un Médico"
          onClose={() => {
            setVisibleDoctorModal(false);
          }}
          size={"lg"}
        >
          <div className="w-100 overflow-auto" style={{ height: 300 }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ width: 20 }}></th>
                  <th>Doctor</th>
                  <th>Nro de Identificación</th>
                  <th>Especialidad</th>
                </tr>
              </thead>
              <tbody>
                {doctors?.length > 0
                  ? doctors.map((doc: Doctor, index) =>
                      index < LIMIT_DOCS ? (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              className="form-check-input form-check-success p-2"
                              onClick={() => {
                                setSelectedDoctor(doc);
                                setShowDoctorError(false);
                                setVisibleDoctorModal(false);
                              }}
                              checked={selectedDoctor?._id === doc?._id}
                            />
                          </td>
                          <td>
                            {doc?.firstName || ""} {doc?.lastName || ""}{" "}
                            {doc?.secondLastName || ""}
                          </td>
                          <td>
                            {doc?.documentType?.code || ""}
                            {doc?.documentNumber
                              ? ` - ${doc?.documentNumber}`
                              : ""}
                          </td>
                          <td>{doc?.speciality || ""}</td>
                        </tr>
                      ) : null
                    )
                  : null}
              </tbody>
            </table>
          </div>
        </ModalSearchComponent>
        <ExamsByMedicModal
          exams={exams}
          doctor={selectedDoctor}
          tableId={tableGenerateReportId}
          visible={visibleReportModal}
          title="Reporte de comisión generado"
          closeModal={() => setVisibleReportModal(false)}
        ></ExamsByMedicModal>
      </LayoutContainer>
    </>
  );
};

export default ExamsByMedicComponent;
