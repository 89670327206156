import React from "react";
import { CImage } from "@coreui/react";

//@ts-ignore
import labbioImage from "../assets/imgs/logo-blanco-2.png";

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <div
      className="min-vh-100 d-flex flex-row align-items-center"
      style={{ backgroundColor: "#D9D9D9" }}
    >
      <div className="signwall-container">
        <div className="card-group">
          <div className="banner">
            <CImage
              src={labbioImage}
              className="banner-image"
              alt="logo labbio"
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
