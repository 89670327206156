import { GENERAL_API } from "../../enviroments/env";
import { ConfigResponse } from "./types";

export const getConfig = (token: string): Promise<ConfigResponse> =>
  fetch(`${GENERAL_API}/configs`, {
    method: "GET",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${token}`,
    },
  })
    .then((res) => res.json())
    .catch((res) => res.json());

export const putConfig = (
  token: string,
  id: string,
  setting: any
): Promise<ConfigResponse> =>
  fetch(`${GENERAL_API}/configs/${id}`, {
    method: "PUT",
    cache: "no-cache",
    body: JSON.stringify({
      ...setting,
    }),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${token}`,
    },
  })
    .then((res) => res.json())
    .catch((res) => res.json());
