import React from "react";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { AuthRouter } from "./AuthRouter";
import DefaultLayout from "../layout/DefaultLayout";
import {
  authStatus,
  useAuthContext,
  AuthProvider,
} from "../context/AuthContext";
import firebase, { FirebaseContext } from "../firebase";
import { Provider } from "react-redux";
import { store } from "../store/store";

import { LAST_PATH } from "../utils/constants";
const AppRouter = () => {
  const { status, user } = useAuthContext();
  const history = useHistory();
  React.useEffect(() => {
    if (status === authStatus.Ready) {
      if (!user) {
        if (!location.href.includes("/auth/")) {
          history.replace("/auth/login");
        }
      } else {
        const PATH = sessionStorage.getItem(LAST_PATH) || "/";
        history.replace(PATH);
      }
    }
  }, [status]);

  return (
    <Provider store={store}>
      <FirebaseContext.Provider value={{ firebase }}>
        <Switch>
          <PublicRoute
            path="/auth"
            isAuthenticated={user ? true : false}
            component={AuthRouter}
          />
          <PrivateRoute
            path="/"
            isAuthenticated={user ? true : false}
            component={DefaultLayout}
          />
          <Redirect to={"/auth/404"}></Redirect>
        </Switch>
      </FirebaseContext.Provider>
    </Provider>
  );
};

export const AppRouterComponentContainer = () => (
  <Router>
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  </Router>
);
