import React from "react";
import { Switch, Route } from "react-router-dom";

import LoginScreen from "../views/pages/login";
import RestoreScreen from "../views/pages/restore";
import RecoveryScreen from "../views/pages/recovery";
import Page404Screen from "../views/pages/page404";

export const AuthRouter = () => {
  return (
    <Switch>
      <Route exact path="/auth/login" component={LoginScreen} />
      <Route exact path="/auth/restore" component={RestoreScreen} />
      <Route exact path="/auth/recovery" component={RecoveryScreen} />
      <Route path="*" component={Page404Screen} />
    </Switch>
  );
};
