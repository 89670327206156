import { GENERAL_API } from "../../enviroments/env";
import { Auth, AuthResponse } from "./types";

export const authentication = ({
  username,
  password,
}: Auth): Promise<AuthResponse> =>
  fetch(`${GENERAL_API}/auth/signin`, {
    method: "POST",
    cache: "no-cache",
    body: JSON.stringify({
      username: username,
      password: password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .catch((res) => res.json());

export const refreshToken = (token: string): Promise<AuthResponse> =>
  fetch(`${GENERAL_API}/auth/token/renew`, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${token}`,
    },
  })
    .then((res) => res.json())
    .catch((res) => res.json());

export const verifyToken = (token: string): Promise<AuthResponse> =>
  fetch(`${GENERAL_API}/auth/token/verify`, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${token}`,
    },
  })
    .then((res) => res.json())
    .catch((res) => res.json());

export const recoveryPassword = ({ username }: Auth) =>
  fetch(`${GENERAL_API}/auth/password/recovery`, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
    }),
  })
    .then((res) => res.json())
    .catch((res) => res.json());

export const changePassword = (
  token: string,
  {
    newPassword,
    confirmPassword,
  }: { newPassword: string; confirmPassword: string }
) =>
  fetch(`${GENERAL_API}/auth/password/change`, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${token}`,
    },
    body: JSON.stringify({
      newPassword,
      confirmPassword,
    }),
  })
    .then((res) => res.json())
    .catch((res) => res.json());
