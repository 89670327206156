import React from "react";
import {
  CAvatar,
  CDropdown,
  CDropdownHeader,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { cilAccountLogout, cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

//@ts-ignore
import avatarDefault from "../../assets/imgs/avatars/avatar-default.png";
import { Link, useHistory } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";

const AppHeaderDropdown = () => {
  const { cleanAuth, user } = useAuthContext();

  const history = useHistory();

  const closeSession = () => {
    cleanAuth();
    history.replace("/auth/login");
  };

  let userImage = "";

  if (user?.image) {
    userImage = user?.image;
  } else if (user?.employee?.image) {
    userImage = user?.employee?.image;
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle className="py-0 me-1" caret={false}>
        <CAvatar src={userImage || avatarDefault} size="lg" />
      </CDropdownToggle>
      <CDropdownMenu
        className="pt-0 me-1 bg-black bg-gradient"
        placement="bottom-end"
      >
        <CDropdownHeader className="bg-black text-white fw-semibold py-2">
          MI CUENTA
        </CDropdownHeader>

        <Link className="dropdown-item text-white" to="/mi-perfil">
          <CIcon icon={cilUser} className="me-2" />
          Editar Perfil
        </Link>
        <button
          className="dropdown-item text-white"
          type="button"
          onClick={() => closeSession()}
        >
          <CIcon icon={cilAccountLogout} className="me-2" />
          Cerrar Sesión
        </button>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
