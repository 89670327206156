import { Link } from "react-router-dom";
import * as React from "react";
import useForm from "../../hooks/useForm";
import { InputForm } from "./controlsForm";
import CIcon from "@coreui/icons-react";
import {
  cilPencil,
  cilSearch,
  cilFolder,
  cilFolderOpen,
  cilTrash,
} from "@coreui/icons";
import { PaginateResponse } from "../../hooks/types";
import { CTooltip } from "@coreui/react";
import {
  BackwardFastIcon,
  BackwardStepIcon,
  ForwardFastIcon,
  ForwardStepIcon,
} from "./icons";
import { SearchModel } from "./searchModel";

export const RedirectionButton = ({
  redirection = "",
  classContainer = "col-12 col-sm-3 col-md-3 col-lg-2",
  classButton = "btn   btn-success w-100 h-auto text-white",
  textButton = "Nuevo",
}) => (
  <div className={classContainer}>
    <Link className={classButton} to={redirection}>
      {textButton}
    </Link>
  </div>
);

export const StatusButton = ({
  className = "btn btn-info btn-icon",
  handleChange,
  status = 0,
  titleArchive = "Archivar",
  titleUnarchive = "Desarchivar",
}: any) => {
  return (
    <CTooltip content={status === 1 ? titleArchive : titleUnarchive}>
      <button type="button" className={className} onClick={handleChange}>
        <CIcon icon={status === 1 ? cilFolder : cilFolderOpen} />
      </button>
    </CTooltip>
  );
};

export const DeleteButton = ({
  className = "btn btn-danger btn-icon",
  handleDelete,
  title = "Eliminar",
}: any) => {
  return (
    <CTooltip content={title}>
      <button type="button" className={className} onClick={handleDelete}>
        <CIcon icon={cilTrash} className="text-white" />
      </button>
    </CTooltip>
  );
};

export const CustomButton = ({
  className = "btn btn-dark btn-icon",
  handleAction,
  title = "",
  icon = cilFolder,
}: any) => {
  return (
    <CTooltip content={title}>
      <button type="button" className={className} onClick={handleAction}>
        <CIcon icon={icon} className="text-white" />
      </button>
    </CTooltip>
  );
};

export const EditItemButton = ({
  className = "btn btn-warning btn-icon",
  code = "",
  path = "",
  title = "",
  icon = cilPencil,
  subsection = "editar",
}) => {
  return (
    <>
      {title ? (
        <CTooltip content={title}>
          <Link
            type="button"
            className={className}
            to={`/${path}/${subsection}/${code}`}
          >
            <CIcon className="text-white" icon={icon} />
          </Link>
        </CTooltip>
      ) : (
        <Link
          type="button"
          className={className}
          to={`/${path}/${subsection}/${code}`}
        >
          <CIcon className="text-white" icon={icon} />
        </Link>
      )}
    </>
  );
};

export const SearchButton = ({
  validators,
  handleSearch,
  textButton = "Buscar",
  className = "my-1 flex-md-row d-sm-flex form-group mx-auto me-lg-0",
}: any) => {
  const stateSchema = {
    search: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    search: {
      ...validators,
    },
  };

  const {
    values: { search },
    errors: { search: searchError },
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, handleSearch);

  return (
    <form className={className} onSubmit={handleOnSubmit}>
      <div className="my-1 flex-md-row d-sm-flex form-group mx-auto me-lg-0">
        <InputForm
          type="search"
          name="search"
          className="form-control br-r-md"
          placeholder="Buscar"
          aria-label="Buscar"
          autoComplete={""}
          value={search || ""}
          error={searchError || false}
          showError={false}
          onChange={handleOnChange}
        />
        <div className="my-1 my-sm-0">
          <button
            className="btn btn-dark text-white w-100 h-100 br-l-md"
            type="submit"
            disabled={disable}
          >
            <CIcon className="d-none d-sm-inline-block" icon={cilSearch} />
            <p className="d-inline-block text-white d-sm-none m-0">
              {textButton}
            </p>
          </button>
        </div>
      </div>
    </form>
  );
};

type IntervalButtonProps = {
  validators: any;
  handleSearch: (filter: any) => void;
  required: boolean;
  className?: string;
  textButton?: string;
  maxLength?: string;
  fullText?: boolean;
};

export const IntervalButton = ({
  validators,
  handleSearch,
  required = false,
  className = "my-1 flex-wrap flex-sm-nowrap d-flex flex-md-row d-sm-flex form-group gap-1 mx-auto me-lg-0",
  textButton = "Filtrar",
  maxLength = undefined,
  fullText = false,
}: IntervalButtonProps) => {
  const stateSchema = {
    startDate: { value: "", error: "" },
    endDate: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    startDate: {
      ...validators,
    },
    endDate: {
      ...validators,
    },
  };

  const {
    values: { startDate, endDate },
    errors: { startDate: startDateError, endDate: endDateError },
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, handleSearch);

  return (
    <form className={className} onSubmit={handleOnSubmit}>
      <div className="my-1 flex-wrap flex-sm-nowrap d-flex flex-md-row d-sm-flex form-group gap-1 mx-auto me-lg-0">
        <InputForm
          name="startDate"
          type="date"
          required={required}
          placeholder="Fecha inicial"
          className="form-control"
          aria-label="Intervalo Inicial"
          max={maxLength}
          value={startDate || ""}
          error={startDateError || false}
          showError={false}
          onChange={handleOnChange}
        />

        <InputForm
          name="endDate"
          type="date"
          required={required}
          className="form-control"
          aria-label="Intervalo Final"
          value={endDate || ""}
          max={maxLength}
          error={endDateError || false}
          showError={false}
          onChange={handleOnChange}
        />

        <div className="my-1 my-sm-0 w-auto d-flex flex-grow-1">
          <button
            className="btn btn-dark text-white w-100 h-100"
            type="submit"
            disabled={disable}
          >
            {fullText ? (
              <>{textButton}</>
            ) : (
              <>
                <CIcon className="d-none d-sm-inline-block" icon={cilSearch} />
                <p className="d-inline-block text-white d-sm-none m-0">
                  {textButton}
                </p>
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export const SubmitButton = ({
  disabled = undefined,
  children = null,
  className = "btn btn-info w-100 text-white",
  style = undefined,
}: {
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: any;
}) => {
  return (
    <button
      type="submit"
      className={className}
      disabled={disabled}
      style={style ? { ...style } : undefined}
    >
      {children}
    </button>
  );
};

type PaginateButtonsProps = {
  paginate: PaginateResponse | null;
  handleChange: (index: number, limit: number) => void;
};

export const PaginateButtons = ({
  paginate,
  handleChange,
}: PaginateButtonsProps) => {
  const {
    hasPrevPage = false,
    prevPage = 0,
    page = 0,
    limit = 0,
    hasNextPage = false,
    nextPage = 0,
    totalPages = 1,
  } = paginate || {};

  const styles = {
    backgroundColor: "#98c3ed",
    border: "0",
  };

  const classes = { button: "btn btn-info mx-2 text-white" };

  return (
    <>
      {hasPrevPage && page > 2 ? (
        <button
          className={classes.button}
          style={{ ...styles }}
          onClick={() => handleChange(1, limit)}
        >
          <BackwardFastIcon></BackwardFastIcon>
        </button>
      ) : null}
      {hasPrevPage ? (
        <button
          className={classes.button}
          style={{ ...styles }}
          onClick={() => handleChange(prevPage || 0, limit)}
        >
          <BackwardStepIcon></BackwardStepIcon>
        </button>
      ) : null}

      {page ? (
        <button className={classes.button} style={{ ...styles }}>
          {page}
        </button>
      ) : null}

      {hasNextPage ? (
        <button
          className={classes.button}
          style={{ ...styles }}
          onClick={() => handleChange(nextPage, limit)}
        >
          <ForwardStepIcon></ForwardStepIcon>
        </button>
      ) : null}

      {hasNextPage && totalPages !== page && totalPages !== page + 1 ? (
        <button
          style={{ ...styles }}
          className={classes.button}
          onClick={() => handleChange(totalPages, limit)}
        >
          <ForwardFastIcon></ForwardFastIcon>
        </button>
      ) : null}
    </>
  );
};
