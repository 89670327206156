import { cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import * as React from "react";

type SearchModelProps = {
  label?: string;
  name: string;
  value: string;
  required?: boolean;
  className?: string;
  placeHolder?: string;
  showError: boolean;
  messageError?: string;
  setShowError: (val: boolean) => void;
  setShowModal: (val: boolean) => void;
};

export const SearchModel = ({
  showError,
  label,
  required,
  value,
  name,
  placeHolder,
  messageError,
  setShowError,
  setShowModal,
  className = "d-flex",
}: SearchModelProps) => {
  return (
    <>
      {label ? (
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
      ) : null}
      <div className={className}>
        <input
          required={required}
          className={`w-100 form-control br-r ${
            showError ? "border border-danger" : ""
          }`}
          placeholder={placeHolder || "Seleccione..."}
          name={name}
          value={value}
          onChange={(e) => {
            if (e.target.value) {
              setShowError(true);
            } else {
              setShowError(false);
            }
          }}
          onBlur={(e) => {
            if (e.target.value) {
              setShowError(true);
            } else {
              setShowModal(false);
            }
          }}
          // disabled={true}
        />
        <button
          type="button"
          className="btn btn-success text-white br-l"
          onClick={() => setShowModal(true)}
        >
          <CIcon icon={cilSearch}></CIcon>
        </button>
      </div>
      {showError && required ? (
        <p className="w-100 pb-0 mb-0 text-danger" style={{ fontSize: 15 }}>
          {messageError || "Este campo es requerido."}
        </p>
      ) : null}
    </>
  );
};
