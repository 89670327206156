import React from "react";
import ExamsByMedicComponent from "./components/reports/examsByMedic";
import { useAuthContext } from "./context/AuthContext";

// Componentes propios del proyecto LABBIO
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));

const HelpComponent = React.lazy(() => import("./components/help/help"));

const MyAccountComponent = React.lazy(() => import("./components/myAccount"));

const EmployeesComponent = React.lazy(
  () => import("./components/employees/employees")
);

const UsersComponent = React.lazy(() => import("./components/users/users"));

const NewUserComponent = React.lazy(() => import("./components/users/newUser"));

const EditUserComponent = React.lazy(
  () => import("./components/users/editUser")
);

const CentersComponent = React.lazy(
  () => import("./components/centers/centers")
);

const NewCenterComponent = React.lazy(
  () => import("./components/centers/newCenter")
);

const EditCenterComponent = React.lazy(
  () => import("./components/centers/editCenter")
);

const NewEmployeeComponent = React.lazy(
  () => import("./components/employees/newEmployee")
);

const EditEmployeeComponent = React.lazy(
  () => import("./components/employees/editEmployee")
);

const DocumentTypesComponent = React.lazy(
  () => import("./components/documentTypes/documents")
);

const NewDocumentTypeComponent = React.lazy(
  () => import("./components/documentTypes/newDocument")
);

const EditDocumentTypeComponent = React.lazy(
  () => import("./components/documentTypes/editDocument")
);

// const ChargesComponent = React.lazy(
//   () => import("./components/charges/charges")
// );

// const NewChargeComponent = React.lazy(
//   () => import("./components/charges/newCharge")
// );

// const EditChargeComponent = React.lazy(
//   () => import("./components/charges/editCharge")
// );

const DoctorsComponent = React.lazy(
  () => import("./components/doctors/doctors")
);

const NewDoctorComponent = React.lazy(
  () => import("./components/doctors/newDoctor")
);

const EditDoctorComponent = React.lazy(
  () => import("./components/doctors/editDoctor")
);

const PatientsComponent = React.lazy(
  () => import("./components/patients/patients")
);

const NewPatientComponent = React.lazy(
  () => import("./components/patients/newPatient")
);

const EditPatientComponent = React.lazy(
  () => import("./components/patients/editPatient")
);

const OrdersComponent = React.lazy(() => import("./components/orders/orders"));

const NewOrderComponent = React.lazy(
  () => import("./components/orders/newOrder")
);

const EditOrderComponent = React.lazy(
  () => import("./components/orders/editOrder")
);
const DetailOrderComponent = React.lazy(
  () => import("./components/orders/detailOrder")
);

const ExamsComponent = React.lazy(() => import("./components/exams/exams"));

const DetailExamComponent = React.lazy(
  () => import("./components/exams/detailExam")
);

const ConfigsComponent = React.lazy(
  () => import("./components/configs/configs")
);

const UtilitiesComponent = React.lazy(() => import("./components/utilities"));

const ReportExamsByMedic = React.lazy(
  () => import("./components/reports/examsByMedic")
);

enum ROLES {
  ADMIN = "admin",
  MODERATOR = "moderator",
}

export const RoutesArray = (): any[] => {
  const { user } = useAuthContext();

  const routes: any = [];

  const role = user?.role?.name;

  const isAdmin = role === ROLES.ADMIN;
  const isModerator = role === ROLES.MODERATOR;

  const routesAdmin = [
    // rutas home page
    { path: "/home", exact: true, name: "Escritorio", element: Dashboard },
    { path: "/", exact: true, name: "Escritorio", element: Dashboard },
    // ruta mi perfil
    {
      path: "/mi-perfil",
      name: "Mi Perfil",
      element: MyAccountComponent,
      exact: true,
    },

    // rutas de pacientes
    {
      path: "/pacientes",
      name: "Pacientes",
      element: PatientsComponent,
      exact: true,
    },
    {
      path: "/pacientes/nuevo",
      name: "Nuevo",
      element: NewPatientComponent,
      exact: true,
    },
    {
      path: "/pacientes/editar/:id",
      name: "Editar",
      element: EditPatientComponent,
      exact: true,
    },
    // rutas empleados
    {
      path: "/empleados",
      name: "Empleado",
      element: EmployeesComponent,
      exact: true,
    },
    // rutas de médicos
    {
      path: "/medicos",
      name: "Médicos",
      element: DoctorsComponent,
      exact: true,
    },
    {
      path: "/medicos/nuevo",
      name: "Nuevo",
      element: NewDoctorComponent,
      exact: true,
    },
    {
      path: "/medicos/editar/:id",
      name: "Editar",
      element: EditDoctorComponent,
      exact: true,
    },

    {
      path: "/ordenes",
      name: "Órdenes",
      element: OrdersComponent,
      exact: true,
    },
    {
      path: "/ordenes/nuevo",
      name: "Nuevo",
      element: NewOrderComponent,
      exact: true,
    },
    {
      path: "/ordenes/editar/:id",
      name: "Editar",
      element: EditOrderComponent,
      exact: true,
    },
    {
      path: "/ordenes/detalle/:id",
      name: "Detalle",
      element: DetailOrderComponent,
      exact: true,
    },
    {
      path: "/examenes",
      name: "Exámenes",
      element: ExamsComponent,
      exact: true,
    },
    {
      path: "/examenes/detalle/:id",
      name: "Detalle",
      element: DetailExamComponent,
      exact: true,
    },

    {
      path: "/reportes/examenes-medico",
      name: "Exámenes por Médico",
      element: ExamsByMedicComponent,
      exact: true,
    },
    {
      path: "/utilidades",
      name: "Unir PDF's",
      element: UtilitiesComponent,
      exact: true,
    },
    // rutas de ayuda
    {
      path: "/ayuda",
      name: "Ayuda",
      element: HelpComponent,
      exact: true,
    },
  ];
  const routesModerator = [
    // rutas de usuarios
    {
      path: "/usuarios",
      name: "Usuarios",
      element: UsersComponent,
      exact: true,
    },
    {
      path: "/usuarios/nuevo",
      name: "Nuevo",
      element: NewUserComponent,
      exact: true,
    },
    {
      path: "/usuarios/editar/:id",
      name: "Editar",
      element: EditUserComponent,
      exact: true,
    },
    // rutas de centros
    {
      path: "/centros",
      name: "Centros",
      element: CentersComponent,
      exact: true,
    },
    {
      path: "/centros/nuevo",
      name: "Nuevo",
      element: NewCenterComponent,
      exact: true,
    },
    {
      path: "/centros/editar/:id",
      name: "Editar",
      element: EditCenterComponent,
      exact: true,
    },
    // rutas de tipos de documento
    {
      path: "/tipos-documento",
      name: "Tipos de Documento",
      element: DocumentTypesComponent,
      exact: true,
    },
    {
      path: "/tipos-documento/nuevo",
      name: "Nuevo",
      element: NewDocumentTypeComponent,
      exact: true,
    },
    {
      path: "/tipos-documento/editar/:id",
      name: "Editar",
      element: EditDocumentTypeComponent,
      exact: true,
    },
    // rutas de cargos
    // {
    //   path: "/cargos",
    //   name: "Cargos",
    //   element: ChargesComponent,
    //   exact: true,
    // },
    // {
    //   path: "/cargos/nuevo",
    //   name: "Nuevo",
    //   element: NewChargeComponent,
    //   exact: true,
    // },
    // {
    //   path: "/cargos/editar/:id",
    //   name: "Editar",
    //   element: EditChargeComponent,
    //   exact: true,
    // },
    // rutas de empleados
    {
      path: "/empleados/nuevo",
      name: "Nuevo",
      element: NewEmployeeComponent,
      exact: true,
    },
    {
      path: "/empleados/editar/:id",
      name: "Editar",
      element: EditEmployeeComponent,
      exact: true,
    },
    // ruta de configuración
    {
      path: "/configuracion",
      name: "Configuración",
      element: ConfigsComponent,
      exact: true,
    },
  ];

  if (isModerator) {
    routes.push(...routesAdmin, ...routesModerator);
  } else if (isAdmin) {
    routes.push(...routesAdmin);
  }

  return routes;
};
export default RoutesArray;
