import * as React from "react";
import { CInputGroupText } from "@coreui/react";
import { formatEmail, formatPass } from "../../../utils/errors";
import useForm from "../../../hooks/useForm";
import { InputForm as Input } from "../../../components/global-components/controlsForm";
import { useAuth, Status, Auth } from "../../../hooks/useAuth";
import { useAuthContext } from "../../../context/AuthContext";
import { setCookie } from "../../../utils/cookies";
import { useConfigs } from "../../../hooks/useConfigs";
import {
  PasswordIcon,
  UserIcon,
} from "../../../components/global-components/icons";
import { COOKIE_REFRESH_TOKEN, COOKIE_TOKEN } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import AuthLayout from "../../../layout/AuthLayout";
import { changeTitleDOM } from "../../../utils/session";

const Login = () => {
  const { updateAuth } = useAuthContext();
  const [showFormatInvalid, setShowFormatInvalid] = React.useState("");
  const { logIn, message, status } = useAuth();
  const { getSettingsConfig } = useConfigs();

  const history = useHistory();

  React.useEffect(() => {
    changeTitleDOM("Login");
  }, []);

  const stateSchema = {
    username: { value: "", error: "" },
    password: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    username: {
      required: true,
      validator: formatEmail(),
    },
    password: {
      required: true,
      validator: formatPass(),
      nospaces: true,
    },
  };

  const checkFormat = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.indexOf(" ") >= 0) {
      setShowFormatInvalid("No se permite espacios");
    } else {
      setShowFormatInvalid("");
    }
  };

  const onSubmitForm = async (data: Auth) => {
    const resp = await logIn(data);
    if (resp?.status === 200) {
      if (resp?.user?.token) {
        setCookie(COOKIE_TOKEN, resp?.user?.token, 1);
      }
      if (resp?.user?.refreshToken) {
        setCookie(COOKIE_REFRESH_TOKEN, resp?.user?.refreshToken, 1);
      }
      const config = await getSettingsConfig();
      if (config?.status === 200) {
        delete resp?.user.token;
        delete resp?.user?.refreshToken;
        delete resp?.user?.recoveryToken;
        if (resp?.user) {
          updateAuth({
            user: resp.user,
            config: config.doc || undefined,
          });
          history.replace("/");
        }
      }
    }
  };

  const {
    values: { username, password },
    errors: { username: usernameError, password: passwordError },
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  return (
    <AuthLayout>
      <div className="card-body p-4 pb-4">
        <p className="w-100 d-flex">
          <span
            style={{ height: 3, backgroundColor: "#686868" }}
            className="d-flex w-100 my-auto"
          />
          <span
            className="text-large-emphasis text-center fw-bolder mx-2 d-flex"
            style={{ color: "#686868" }}
          >
            BIENVENIDO
          </span>
          <span
            style={{ height: 3, backgroundColor: "#686868" }}
            className="d-flex w-100 my-auto"
          />
        </p>

        <form onSubmit={handleOnSubmit}>
          {status === Status.Error ? (
            <div
              className="alert alert-danger text-center p-1"
              role="alert"
              style={{ fontSize: 14 }}
            >
              {message}
            </div>
          ) : (
            ""
          )}
          <div className="input-group mb-3">
            <CInputGroupText>
              <UserIcon fill="#000000"></UserIcon>
            </CInputGroupText>
            <Input
              type="email"
              placeholder="Correo electrónico"
              autoComplete="username"
              disabled={status === Status.Loading}
              name="username"
              required
              value={username || ""}
              onChange={(e) => {
                handleOnChange(e);
              }}
              error={usernameError}
            />
          </div>
          <div className="input-group mb-3">
            <CInputGroupText>
              <PasswordIcon fill="#000000"></PasswordIcon>
            </CInputGroupText>
            <Input
              type="password"
              placeholder="Contraseña"
              autoComplete="current-password"
              disabled={status === Status.Loading}
              required
              name="password"
              value={password || ""}
              onChange={(e) => {
                handleOnChange(e);
                checkFormat(e);
              }}
              error={passwordError || showFormatInvalid}
            />
          </div>
          <div className="row">
            <div className="col-12">
              <button
                className="btn text-white w-100 btn-success"
                type="submit"
                disabled={
                  disable || status === Status.Loading || !!showFormatInvalid
                }
              >
                {status === Status.Loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    &nbsp;Cargando...
                  </>
                ) : (
                  "Iniciar sesión"
                )}
              </button>
            </div>
          </div>
          <button
            className="mt-3 link-dark btn border-none mx-auto d-block mb-3 text-center text-black td-none fw-bold"
            onClick={() => history.push("/auth/recovery")}
          >
            ¿Olvidaste tu contraseña?
          </button>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
