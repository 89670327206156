import * as React from "react";
import { getCookie } from "../../utils/cookies";
import Swal from "sweetalert2";

import {
  getDoctorById,
  getDoctors,
  postDoctor,
  putDoctor,
  deleteDoctor,
} from "./helpers";
import { Doctor } from "./index";
import { BodyParams, PaginateParams, PaginateResponse } from "../types";
import { DoctorResponse } from "./types";
import { COOKIE_TOKEN } from "../../utils/constants";

export enum Status {
  Loading,
  Ready,
  Updating,
  Error,
}

export const useDoctors = () => {
  const [doctors, setDoctors] = React.useState<Doctor[]>([]);
  const [doctorInfo, setDoctorInfo] = React.useState<Doctor | null>(null);
  const [status, setStatus] = React.useState(Status.Loading);
  const [paginateData, setPaginateData] =
    React.useState<PaginateResponse | null>(null);
  const [searchFilter, setSearchFilter] = React.useState<BodyParams>({
    filter: "",
    status: null,
  });

  function setDoctorById(id: string) {
    setStatus(Status.Loading);
    const token = getCookie(COOKIE_TOKEN) || "";
    getDoctorById(token, id).then((response) => {
      if (response?.status === 200) {
        setDoctorInfo(response?.doc || null);
        setStatus(Status.Ready);
      }
    });
  }

  async function getDoctorsByFilter(
    { filter, status }: BodyParams,
    { limit, pageSize }: PaginateParams
  ): Promise<PaginateResponse> {
    const token = getCookie(COOKIE_TOKEN) || "";
    return getDoctors(token, { filter, status }, { limit, pageSize })
      .then((response: PaginateResponse) => {
        if (response?.status === 200) {
          const { docs: doctorsObtained = [] } = response || {};
          setDoctors(doctorsObtained);
          setPaginateData(response);
        } else {
          Swal.fire({
            icon: "error",
            title: "Algo salió mal!",
            text: response?.message || "",
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        }
        setStatus(Status.Ready);
        return response;
      })
      .catch((error) => {
        setStatus(Status.Error);
        return error;
      })
      .finally(() => {
        setSearchFilter({
          ...searchFilter,
          filter,
          status,
        });
      });
  }

  async function updateDoctor(
    id: string,
    doctor: any
  ): Promise<DoctorResponse> {
    setStatus(Status.Updating);
    const token = getCookie(COOKIE_TOKEN) || "";
    return putDoctor(token, id, doctor)
      .then((response) => {
        if (response?.status === 200) {
          Swal.fire({
            icon: "success",
            title: "¡Actualización Exitosa!",
            text: "Médico actualizado exitosamente",
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Algo salió mal!",
            text: response?.message || "",
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        }
        setStatus(Status.Ready);
        return response;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Algo salió mal!",
          text: "Ocurrió un error inesperado",
          timer: 2000,
          confirmButtonColor: "#2e2868",
        });
        setStatus(Status.Ready);
        return error;
      });
  }

  async function changeDoctorStatus(
    id: string,
    status: number
  ): Promise<DoctorResponse> {
    setStatus(Status.Updating);
    const token = getCookie(COOKIE_TOKEN) || "";
    return putDoctor(token, id, {
      isArchive: status === 1 ? true : undefined,
      isUnarchive: status === 0 ? true : undefined,
    })
      .then((response) => {
        if (response?.status === 200) {
          setDoctors(
            doctors.map((doctor: Doctor) =>
              doctor?._id === id ? { ...response?.doc } : doctor
            )
          );
          const doctorStatus =
            response?.doc?.status === 1 ? "desarchivado" : "archivado";
          Swal.fire({
            title: "¡Todo salió bien!",
            icon: "success",
            text: `Médico ${doctorStatus} exitosamente`,
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "¡Algo salió mal!",
            text: response?.message || "",
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        }
        setStatus(Status.Ready);
        return response;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Algo salió mal!",
          text: "Ocurrió un error inesperado",
          timer: 2000,
          confirmButtonColor: "#2e2868",
        });
        setStatus(Status.Ready);
        return error;
      });
  }

  async function eliminateDoctor(id: string): Promise<DoctorResponse> {
    setStatus(Status.Updating);
    const token = getCookie(COOKIE_TOKEN) || "";
    return deleteDoctor(token, id)
      .then((response) => {
        if (response?.status === 204) {
          setDoctors(doctors.filter((doctor: Doctor) => doctor._id !== id));
          Swal.fire({
            title: "¡Todo salió bien!",
            icon: "success",
            text: `Médico eliminado permanentemente`,
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "¡Algo salió mal!",
            text: response?.message || "",
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        }
        setStatus(Status.Ready);
        return response;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Algo salió mal!",
          text: "Ocurrió un error inesperado",
          timer: 2000,
          confirmButtonColor: "#2e2868",
        });
        setStatus(Status.Ready);
        return error;
      });
  }

  async function registerDoctor(doctor: any): Promise<DoctorResponse> {
    setStatus(Status.Updating);
    const token = getCookie(COOKIE_TOKEN) || "";
    return postDoctor(token, doctor)
      .then((response) => {
        if (response?.status === 201) {
          Swal.fire({
            icon: "success",
            title: "¡Registro Exitoso!",
            text: "Médico registrado exitosamente",
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Algo salió mal!",
            text: response?.message || "",
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        }
        setStatus(Status.Ready);
        return response;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Algo salió mal!",
          text: "Ocurrió un error inesperado",
          timer: 2000,
          confirmButtonColor: "#2e2868",
        });
        setStatus(Status.Ready);
        return error;
      });
  }

  function changePage(index: number, limit: number) {
    getDoctorsByFilter(searchFilter, { limit, pageSize: index });
  }

  return {
    doctors,
    registerDoctor,
    updateDoctor,
    changeDoctorStatus,
    eliminateDoctor,
    setDoctorById,
    doctorInfo,
    getDoctorsByFilter,
    paginateData,
    searchFilter,
    changePage,
    status,
  };
};
