import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import { Link } from "react-router-dom";
import { changeTitleDOM } from "../../../utils/session";

const Page404 = () => {
  React.useEffect(() => {
    changeTitleDOM("404");
  }, []);
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center d-flex">
          <CCol md={7} lg={6} className="d-flex">
            <div className="clearfix d-flex align-items-center">
              <h1 className="display-2 mb-auto">404</h1>
            </div>
            <div className="mx-3 d-flex flex-column justify-content-center">
              <h4 className="d-flex">Esta página no se encuentra disponible</h4>
              <Link className="text-medium-emphasis d-flex" to={"/auth/login"}>
                Volver a iniciar sesión
              </Link>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Page404;
