import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { Doctor } from "../../../hooks/useDoctors";
import { ExamType, Exam } from "../../../hooks/useExams/types";
import { setFormatDate } from "../../../utils/formats";
import { ExportButtons } from "../../global-components/exportButtons";

type ExamsByModalProps = {
  exams: Exam[];
  visible: boolean;
  title: string;
  tableId: string;
  doctor: Doctor | null;
  closeModal: () => void;
};

export const ExamsByMedicModal = ({
  exams = [],
  visible = false,
  title = "Reporte de comisión generado por médico",
  tableId = "",
  doctor = null,
  closeModal,
}: ExamsByModalProps) => {
  const obtainTotalAmount = (list: Exam[]) => {
    let total = 0;

    list.map((exam: Exam) => {
      if (exam?.commissionDoctor) {
        total = total + exam.commissionDoctor;
      }
    });
    return total;
  };

  return (
    <CModal
      visible={visible}
      color="#6f0009"
      size="xl"
      onClose={() => {
        closeModal();
      }}
      className="w-100"
    >
      <CModalHeader closeButton={true}>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="w-100 overflow-auto" style={{ height: 300 }}>
          <table
            className="table table-striped border-3"
            id={tableId}
            style={{
              minWidth: 1200,
            }}
          >
            <thead>
              <tr>
                <th colSpan={8} className="text-center bg-black">
                  {title} por {doctor?.firstName?.toUpperCase() || ""}{" "}
                  {doctor?.lastName?.toUpperCase() || ""}
                </th>
              </tr>
              <tr>
                <th>Nro. Examen</th>
                <th>Nro. Orden</th>
                <th>Paciente</th>
                <th>Tipo de examen</th>
                <th>Fecha de emisión</th>
                <th>% de comisión</th>
                <th>Costo total del examen</th>
                <th className="fw-bold" style={{ width: 120 }}>
                  Total de comisión
                </th>
              </tr>
            </thead>
            <tbody>
              {exams.length > 0
                ? exams.map((res: Exam, index: number) => {
                    const {
                      examNumber,
                      order,
                      patient,
                      totalAmount,
                      examType,
                      percentDoctor,
                      commissionDoctor,
                      createdDate,
                    } = res || {};
                    return (
                      <tr key={index}>
                        <td>{examNumber || ""}</td>
                        <td>{order?.orderNumber || ""}</td>
                        <td>
                          {patient?.firstName || ""} {patient?.lastName || ""}{" "}
                          {patient?.secondLastName || ""}
                        </td>
                        <td>
                          {examType === ExamType.ULTRASOUND
                            ? "Ecografía"
                            : "" || examType === ExamType.ANALYSIS
                            ? "Análisis"
                            : ""}
                        </td>
                        <td>
                          {setFormatDate({ order: 1, date: createdDate }) || ""}
                        </td>
                        <td>{percentDoctor?.toFixed(2) || "0.00"}</td>
                        <td>{totalAmount?.toFixed(2) || "0.00"}</td>
                        <td>{commissionDoctor?.toFixed(2) || "0.00"}</td>
                      </tr>
                    );
                  })
                : null}
              {exams?.length ? (
                <tr className="bg-black">
                  <td
                    className="fw-bold bg-black bg-gradient text-white"
                    colSpan={7}
                  >
                    TOTAL A PAGAR
                  </td>
                  <td
                    className="fw-bold bg-black bg-gradient text-white"
                    colSpan={1}
                  >
                    {obtainTotalAmount(exams)?.toFixed(2) || "0.00"}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </CModalBody>
      <CModalFooter>
        <ExportButtons
          tableId={tableId}
          documentName="report-exams-by-medic"
          classButtons="btn btn-success text-white"
          classContainer="ms-auto"
          showExportCSV={false}
          showExportClipBoard={false}
          showExportPDF={false}
          textExcelButton="Exportar a EXCEL"
        />
        <CButton
          color="dark"
          onClick={() => {
            closeModal();
          }}
        >
          Cerrar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};
