import { GENERAL_API } from "../../enviroments/env";
import { BodyParams, PaginateParams, PaginateResponse } from "../types";

import { ExamResponse } from "./types";

export const getExams = (
  token: string,
  { startDate, endDate, status = null, doctorId = undefined }: BodyParams,
  { limit = 5, pageSize = 1 }: PaginateParams
): Promise<PaginateResponse> =>
  fetch(`${GENERAL_API}/exams/consult/?limit=${limit}&pageSize=${pageSize}`, {
    method: "POST",
    cache: "no-cache",
    body: JSON.stringify({
      startDate,
      endDate,
      status,
      doctorId,
    }),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${token}`,
    },
  })
    .then((res) => res.json())
    .catch((res) => res.json());

export const getExamById = (token: string, id: string): Promise<ExamResponse> =>
  fetch(`${GENERAL_API}/exams/${id}`, {
    method: "GET",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${token}`,
    },
  })
    .then((res) => res.json())
    .catch((res) => res.json());

export const deleteExam = (token: string, id: string): Promise<ExamResponse> =>
  fetch(`${GENERAL_API}/exams/${id}`, {
    method: "DELETE",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${token}`,
    },
  })
    .then((res) => res.json())
    .catch((res) => res.json());
