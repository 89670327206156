const firebaseConfig = {
  apiKey: "AIzaSyBW-pQ4tcawYKZJ7ZIxOyT9-jN27SQpcxc",
  authDomain: "labbio-admin-5beb9.firebaseapp.com",
  projectId: "labbio-admin-5beb9",
  storageBucket: "labbio-admin-5beb9.appspot.com",
  messagingSenderId: "1037678465294",
  appId: "1:1037678465294:web:75f3adfbb9224bf35d875f"
};

export default firebaseConfig;
