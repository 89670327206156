import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { CModalProps } from "@coreui/react/dist/components/modal/CModal";
import { SearchButton } from "./globalButtons";

type ModalWarningProps = {
  title: string;
  subTitle?: string;
  description?: string;
  action: any;
  onClose: any;
  visible: boolean;
  actionbuttonMessage: string;
  colorButton?: string;
};

export const ModalWarningComponent = ({
  title = "",
  subTitle = "",
  description = "",
  action,
  onClose,
  visible,
  actionbuttonMessage = "",
  colorButton = "danger",
}: ModalWarningProps) => {
  return (
    <CModal
      visible={visible}
      onClose={() => {
        onClose();
      }}
    >
      <CModalHeader closeButton={true}>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p className="my-0">{subTitle}</p>
        {description ? (
          <p className="my-1 fw-bold" style={{ fontSize: "14px" }}>
            {description}
          </p>
        ) : null}
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          className="text-white"
          onClick={() => {
            onClose();
          }}
        >
          Cerrar
        </CButton>
        <CButton
          color={colorButton}
          className="text-white"
          onClick={() => action()}
        >
          {actionbuttonMessage}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

type ModalSearchProps = {
  title: string;
  onClose: () => void;
  selected?: any;
  visible: boolean;
  validators: any;
  onSearch: (obj: any) => void;
  colorButton?: string;
  size?: CModalProps["size"];
  onClean: () => void;
  children: React.ReactNode;
};

export const ModalSearchComponent = ({
  title,
  visible,
  validators,
  onSearch,
  onClose,
  onClean,
  size,
  children,
}: ModalSearchProps) => {
  return (
    <CModal
      visible={visible}
      onClose={() => {
        onClose();
      }}
      size={size}
    >
      <CModalHeader closeButton={true}>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <SearchButton
          validators={validators}
          handleSearch={onSearch}
          className="align-items-end my-1 col-12 flex-md-row d-sm-flex"
        />
        {children}
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          className="text-white"
          onClick={() => onClean()}
        >
          Limpiar
        </CButton>
        <CButton color="dark" onClick={() => onClose()}>
          Cerrar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};
