import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import firebaseConfig from "./config";

class Firebase {
  storage: app.storage.Storage;
  uploadFile: (string: any) => Promise<any>;
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(firebaseConfig);
    }

    // inicialización de storage
    this.storage = app.storage();

    // subida de imagen
    this.uploadFile = async function uploadFile(archivo) {
      try {
        return await this.storage.ref(`${archivo}`).put(archivo);
      } catch (error) {
        return error;
      }
    };
  }
}

const firebase = new Firebase();
export default firebase;
