import * as React from "react";
import { CInputGroupText } from "@coreui/react";
import { formatEmail } from "../../../utils/errors";
import useForm from "../../../hooks/useForm";
import { InputForm as Input } from "../../../components/global-components/controlsForm";
import { useAuth, Status, Auth } from "../../../hooks/useAuth";
import { EmailIcon } from "../../../components/global-components/icons";
import { Link, useHistory } from "react-router-dom";
import AuthLayout from "../../../layout/AuthLayout";
import { changeTitleDOM } from "../../../utils/session";

const Recovery = () => {
  const { recovery, message, status } = useAuth();

  const history = useHistory();

  React.useEffect(() => {
    changeTitleDOM("Recuperación de cuenta");
  }, []);

  const stateSchema = {
    username: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    username: {
      required: true,
      validator: formatEmail(),
    },
  };

  const onSubmitForm = async (data: Auth) => {
    const resp = await recovery(data);
    if (resp?.status === 200) {
    }
  };

  const {
    values: { username },
    errors: { username: usernameError },
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  return (
    <AuthLayout>
      <div className="card-body p-4 pb-4">
        <p className="w-100 d-flex">
          <span
            style={{ height: 3, backgroundColor: "#686868" }}
            className="d-flex w-100 my-auto"
          />
          <span
            className="text-large-emphasis text-center fw-bolder mx-2 d-flex"
            style={{ color: "#686868", lineHeight: "20px" }}
          >
            Restaurar Contraseña
          </span>
          <span
            style={{ height: 3, backgroundColor: "#686868" }}
            className="d-flex w-100 my-auto"
          />
        </p>

        <form onSubmit={handleOnSubmit}>
          {message ? (
            <div
              className={`alert ${
                status === Status.Error ? "alert-danger" : "alert-success"
              } text-center p-1 flex-column`}
              role="alert"
              style={{ fontSize: 14 }}
            >
              <span>{message}</span>
              {status === Status.Ready ? (
                <Link
                  to={"/auth/login"}
                  className="link text-success"
                  style={{
                    fontSize: "15px",
                  }}
                >
                  iniciar sesión
                </Link>
              ) : null}
            </div>
          ) : (
            ""
          )}
          <div className="input-group mb-3">
            <CInputGroupText>
              <EmailIcon fill="#000000"></EmailIcon>
            </CInputGroupText>
            <Input
              type="email"
              placeholder="Correo electrónico"
              autoComplete="username"
              disabled={status === Status.Loading}
              name="username"
              required
              value={username || ""}
              onChange={(e) => {
                handleOnChange(e);
              }}
              error={usernameError}
            />
          </div>
          <div className="row">
            <div className="col-12">
              <button
                className="btn text-white w-100 btn-success"
                type="submit"
                disabled={disable || status === Status.Loading}
              >
                {status === Status.Loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    &nbsp;Cargando...
                  </>
                ) : (
                  "Restaurar"
                )}
              </button>
            </div>
          </div>
        </form>
        <button
          className="link-dark btn border-none mt-3 mx-auto d-flex mb-3 text-center text-black td-none fw-bold"
          onClick={() => {
            history.push("/auth/login");
          }}
        >
          Regresar a iniciar sesión
        </button>
      </div>
    </AuthLayout>
  );
};

export default Recovery;
