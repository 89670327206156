import { cilFindInPage } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import React from "react";
import { setFormatDate } from "../../../utils/formats";
import { Exam } from "../../../hooks/useExams";
import { CTooltip } from "@coreui/react";

type ExamItemProps = {
  index: number;
  exam: Exam;
};

export const ExamItem: React.FC<ExamItemProps> = ({ index, exam }) => {
  const {
    patient,
    order,
    recepcionist = undefined,
    examNumber,
    createdDate,
    totalAmount = 0,
    totalGain = 0,
    commissionDoctor = 0,
    document,
  } = exam || {};

  return (
    <tr>
      <td>{index}</td>
      <td>{examNumber || ""}</td>
      <td>{order?.orderNumber || ""}</td>
      <td>{`${patient?.firstName || ""} ${patient?.lastName || ""}`.trim()}</td>
      <td>
        {`${recepcionist?.firstName || ""} ${
          recepcionist?.lastName || ""
        }`.trim()}
      </td>
      <td>{setFormatDate({ order: 0, date: createdDate })}</td>
      <td>{totalAmount?.toFixed(2) || "0.00"}</td>
      <td>{commissionDoctor.toFixed(2) || "0.00"}</td>
      <td>{totalGain?.toFixed(2) || "0.00"}</td>
      <td>
        <div className="selection-btn">
          <div className="btn-group">
            <CTooltip content={"Revisar Examen"}>
              <button
                type="button"
                className="btn btn-success btn-icon text-white"
                onClick={() => window.open(document, "_blank")}
              >
                <CIcon icon={cilFindInPage} color="#fffff" />
              </button>
            </CTooltip>
          </div>
        </div>
      </td>
    </tr>
  );
};
