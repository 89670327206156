import React from "react";

import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CImage,
  CNavItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";

import { AppBreadcrumb } from "./index";
import { AppHeaderDropdown } from "./header";
//@ts-ignore
import labbioImage from "../assets/imgs/logo-blanco-2.png";
import { useDispatch, useSelector } from "react-redux";

import { types } from "../types/types";
import { useAuthContext } from "../context/AuthContext";
import { formatRolName } from "../utils/formats";
// import { ToggleButtonThemeComponent } from "./global-components/toggleButtonTheme";

const AppHeader = () => {
  const distpatch = useDispatch();

  const { sidebarShow } = useSelector((state) => state.nav);
  const { user } = useAuthContext();
  const { config } = useAuthContext();

  let nameProfile = "";
  if (user?.fullname) {
    nameProfile = user?.fullname;
  } else if (user?.username) {
    nameProfile = user.username;
  } else {
    nameProfile = user ? "Invitado" : "";
  }

  return (
    <CHeader
      position="sticky"
      className="mb-4"
      style={{ backgroundColor: "#2a2b36" }}
    >
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() =>
            distpatch({ type: types.nav.set, payload: !sidebarShow })
          }
        >
          <CIcon icon={cilMenu} size="lg" style={{ color: "#fff" }} />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none text-light">
          {config?.url ? (
            <a href={config?.url} target={"_blank"} rel="noreferrer">
              <CImage src={labbioImage} height={48} alt="Logo" />
            </a>
          ) : (
            <CImage src={labbioImage} height={48} alt="Logo" />
          )}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto text-light">
          <CNavItem className="d-block fw-bold">
            Usuario: <span className="fw-normal">{nameProfile}</span>
          </CNavItem>
          <CNavItem className="d-block fw-bold ms-5">
            Rol Principal:&nbsp;
            <span className="fw-normal">
              {formatRolName(user?.role?.name || "")}
            </span>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-auto">
          {/* <ToggleButtonThemeComponent /> */}
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
