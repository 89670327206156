import { GeneralResponse } from "../types";

export const enum OperationType {
  IDENTITY = "identity",
  TRANSACTION = "transaction",
}

export type DocumentType = {
  _id?: string;
  name?: string;
  code?: string;
  description?: string;
  operation?: OperationType; // "comprobante" o "persona"
  length?: number;
  status?: number;
  createdDate?: string;
  updatedDate?: string;
};

export type DocumentTypeResponse = GeneralResponse & {
  doc?: DocumentType | null;
};
