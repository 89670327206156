import { GeneralResponse } from "../types";
import { Center } from "../useCenters";
import { Patient } from "../usePatients";
import { Employee } from "../useEmployees";
import { Doctor } from "../useDoctors";
import { Order, Result } from "../useOrders";

export const enum ExamType {
  ANALYSIS = "analysis",
  ULTRASOUND = "ultrasound",
}

export type Exam = {
  _id?: string;
  examNumber?: string;
  order?: Order;
  patient?: Patient;
  doctor?: Doctor;
  recepcionist?: Employee;
  center?: Center;
  examType?: ExamType;
  patientNumber?: string;
  dateOfReceiptOfSamples?: string;
  deliveryDate: string;
  totalAmount?: number;
  percentDoctor?: number;
  commissionDoctor?: number;
  totalGain?: number;
  document?: string;
  results?: Result[];
  createdDate?: string;
  updatedDate?: string;
};

export type ExamResponse = GeneralResponse & {
  doc?: Exam | null;
};
