import React, { Ref } from "react";

type InputProps = {
  type?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  error?: string | boolean | null;
  value: string | null;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  readonly?: boolean;
  className?: string;
  showError?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: number | string;
  max?: number | string;
  step?: string;
  ref?: any;
  accept?: string;
  multiple?: boolean;
};

export const InputForm: React.FC<InputProps> = ({
  type = "text",
  placeholder = "",
  autoComplete = "",
  onChange,
  disabled,
  error = "",
  value = "",
  name = "",
  required = false,
  className = "form-control",
  showError = true,
  minLength = 2,
  maxLength = 50,
  min = undefined,
  max = undefined,
  step = undefined,
  readonly = undefined,
  ref = undefined,
  accept = undefined,
  multiple = undefined,
}) => {
  return (
    <>
      <input
        id={name}
        type={type}
        accept={accept}
        min={min}
        max={max}
        name={name}
        step={step}
        multiple={multiple}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className={`${className} ${error ? "border border-danger" : ""}`}
        value={value || ""}
        readOnly={readonly}
        required={required}
        disabled={disabled}
        onBlur={onChange}
        onChange={onChange}
        ref={ref}
      />
      {showError ? (
        <p
          className="w-100 mt-1 pb-0 mb-0 text-danger"
          style={{ fontSize: 15 }}
        >
          {error}
        </p>
      ) : null}
    </>
  );
};

type SelectProps = {
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  error?: string | boolean | null;
  value: string | null;
  children: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  className?: string;
  showError?: boolean;
};

export const SelectForm: React.FC<SelectProps> = ({
  placeholder = "",
  onChange,
  disabled,
  error = "",
  value = "",
  name = "",
  required = false,
  className = "form-select",
  showError = true,
  children,
}) => {
  return (
    <>
      <select
        id={name}
        name={name}
        placeholder={placeholder}
        className={`${className} ${error ? "border border-danger" : ""}`}
        value={value || ""}
        required={required}
        disabled={disabled}
        onBlur={onChange}
        onChange={onChange}
      >
        {children}
      </select>
      {showError ? (
        <p
          className="w-100 mt-1 pb-0 mb-0 text-danger"
          style={{ fontSize: 15 }}
        >
          {error}
        </p>
      ) : null}
    </>
  );
};

type TextAreaProps = {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  error?: string | boolean | null;
  value: any;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  ref?: any;
  required?: boolean;
  className?: string;
  showError?: boolean;
  maxLength?: number;
  rows?: number;
  cols?: number;
};

export const TextAreaForm: React.FC<TextAreaProps> = ({
  placeholder = "",
  onChange,
  disabled,
  error = false,
  value = "",
  name = "",
  ref,
  required = false,
  className = "form-control",
  showError = true,
  maxLength = 50,
  rows = 1,
  cols = 1,
}) => {
  return (
    <>
      <textarea
        ref={ref}
        id={name}
        name={name}
        rows={rows}
        cols={cols}
        maxLength={maxLength}
        placeholder={placeholder}
        className={`${className} ${error ? "border border-danger" : ""}`}
        value={value}
        required={required}
        disabled={disabled}
        onChange={onChange}
        onBlur={onChange}
      ></textarea>
      {showError ? (
        <p className="w-100 pb-0 mb-0 text-danger" style={{ fontSize: 15 }}>
          {error}
        </p>
      ) : null}
    </>
  );
};
