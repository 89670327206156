import React from "react";

import { Route } from "react-router-dom";
import DefaultLayout from "../layout/DefaultLayout";

export const PublicRoute = ({
  path = "/auth",
  component: Component,
  isAuthenticated = false,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      path={path}
      component={(props: any) =>
        isAuthenticated ? (
          <DefaultLayout {...props}></DefaultLayout>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
