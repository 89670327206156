/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import { COOKIE_REFRESH_TOKEN, COOKIE_TOKEN } from "../../utils/constants";
import { deleteCookie, getCookie, setCookie } from "../../utils/cookies";
import { isStorageAvailable } from "../../utils/storage";
import {
  authentication,
  refreshToken,
  recoveryPassword,
  changePassword,
  verifyToken,
} from "./helpers";
import { Auth, AuthResponse } from "./types";

export enum Status {
  Loading,
  Ready,
  Updating,
  Error,
  Verify,
}

export const useAuth = () => {
  const [status, setStatus] = React.useState(Status.Ready);
  const [message, setMessage] = React.useState("");

  async function logIn({ username, password }: Auth): Promise<AuthResponse> {
    setStatus(Status.Loading);
    return authentication({ username, password })
      .then((response) => {
        if (response.status === 200) {
          setStatus(Status.Ready);
        } else {
          setMessage(response?.message || "");
          setStatus(Status.Error);
          setTimeout(() => {
            setMessage("");
            setStatus(Status.Ready);
          }, 3000);
        }
        return response;
      })
      .catch((error) => {
        setStatus(Status.Error);
        setMessage("Ocurrió un error inesperado");
        setTimeout(() => {
          setMessage("");
          setStatus(Status.Ready);
        }, 3000);
        return error;
      });
  }

  async function updateToken(): Promise<AuthResponse> {
    setStatus(Status.Loading);
    const token = getCookie(COOKIE_REFRESH_TOKEN) || "";
    return refreshToken(token)
      .then((res) => {
        if (res?.status === 200) {
          setCookie(COOKIE_TOKEN, res?.user?.token || "", 1);
          setCookie(COOKIE_REFRESH_TOKEN, res?.user?.refreshToken || "", 1);
        }
        setStatus(Status.Verify);
        return res;
      })
      .catch((res) => res);
  }

  async function verifyRecoveryToken(token: string): Promise<AuthResponse> {
    setStatus(Status.Loading);
    return verifyToken(token)
      .then((res) => {
        setStatus(Status.Ready);
        return res;
      })
      .catch((res) => {
        setStatus(Status.Ready);
        return res;
      });
  }

  async function recovery({ username }: Auth): Promise<AuthResponse> {
    setStatus(Status.Loading);
    return recoveryPassword({ username })
      .then((res) => {
        if (res?.status === 200) {
          setMessage(`Correo enviado a ${username}`);
          setStatus(Status.Ready);
          setTimeout(() => {
            setMessage("");
          }, 3500);
        } else {
          setStatus(Status.Error);
          setMessage(res?.message || "");
          setTimeout(() => {
            setMessage("");
            setStatus(Status.Ready);
          }, 3500);
        }
        return res;
      })
      .catch((error) => {
        setStatus(Status.Error);
        setMessage("Ocurrió un error inesperado");
        setTimeout(() => {
          setMessage("");
          setStatus(Status.Ready);
        }, 3000);
        return error;
      });
  }

  async function updatePassword(
    token: string,
    {
      newPassword,
      confirmPassword,
    }: { newPassword: string; confirmPassword: string }
  ): Promise<AuthResponse> {
    setStatus(Status.Loading);
    return changePassword(token, { newPassword, confirmPassword })
      .then((res) => {
        if (res?.status === 200) {
          setMessage(res?.message || "");
          setStatus(Status.Ready);
          setTimeout(() => {
            setMessage("");
          }, 2000);
        } else {
          setStatus(Status.Error);
          setMessage(res?.message || "");
          setTimeout(() => {
            setMessage("");
            setStatus(Status.Ready);
          }, 2000);
        }
        return res;
      })
      .catch((error) => {
        setStatus(Status.Error);
        setMessage("Ocurrió un error inesperado");
        setTimeout(() => {
          setMessage("");
          setStatus(Status.Ready);
        }, 3000);
        return error;
      });
  }

  const destroyStorageAndCookies = (): void => {
    if (isStorageAvailable("sessionStorage")) {
      Object.keys(sessionStorage).forEach((key) => delete sessionStorage[key]);
    }
    if (isStorageAvailable("localStorage")) {
      Object.keys(localStorage).forEach((key) => delete localStorage[key]);
    }
    const token = getCookie(COOKIE_TOKEN);
    const refreshToken = getCookie(COOKIE_REFRESH_TOKEN);
    if (token) {
      deleteCookie(COOKIE_TOKEN);
    }
    if (refreshToken) {
      deleteCookie(COOKIE_REFRESH_TOKEN);
    }
  };

  function logoutUser() {
    destroyStorageAndCookies();
    return true;
  }

  return {
    logIn,
    updateToken,
    updatePassword,
    recovery,
    verifyRecoveryToken,
    logoutUser,
    status,
    message,
  };
};
