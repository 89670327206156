import * as React from "react";
import { refreshToken } from "../hooks/useAuth/helpers";
import { Config } from "../hooks/useConfigs";
import { getConfig } from "../hooks/useConfigs/helpers";
import { User } from "../hooks/useUsers";
import { COOKIE_REFRESH_TOKEN, COOKIE_TOKEN } from "../utils/constants";
import { deleteCookie, getCookie, setCookie } from "../utils/cookies";
import { isStorageAvailable } from "../utils/storage";

export type ValuesOf<T> = { [P in keyof T]: T[P] };

type StatusOptions = ValuesOf<authStatus>;
enum authStatus {
  Error,
  Loading,
  Ready,
}

type AuthProviderProps = {
  children: React.ReactNode;
};

type AuthProviderValue = {
  status: StatusOptions | undefined;
  user: User | undefined;
  config: Config | undefined;
  updateAuth: ({
    user,
    config,
  }: {
    user: User | undefined;
    config: Config | undefined;
  }) => void;
  cleanAuth: () => void;
  error?: any | undefined;
};

const AuthContext = React.createContext<AuthProviderValue | undefined>(
  undefined
);

const useAuthContext = (): AuthProviderValue => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext debe ser usado dentro de un AuthProvider");
  }
  return context;
};

const destroyStorageAndCookies = (): void => {
  if (isStorageAvailable("sessionStorage")) {
    Object.keys(sessionStorage).forEach((key) => delete sessionStorage[key]);
  }
  if (isStorageAvailable("localStorage")) {
    Object.keys(localStorage).forEach((key) => delete localStorage[key]);
  }
  const token = getCookie(COOKIE_TOKEN);
  const refreshToken = getCookie(COOKIE_REFRESH_TOKEN);
  if (token) {
    deleteCookie(COOKIE_TOKEN);
  }
  if (refreshToken) {
    deleteCookie(COOKIE_REFRESH_TOKEN);
  }
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const cleanAuth = () => {
    destroyStorageAndCookies();
    setValue({
      ...value,
      status: authStatus.Ready,
      user: undefined,
      error: undefined,
    });
  };

  const [value, setValue] = React.useState<AuthProviderValue>({
    user: undefined,
    config: undefined,
    updateAuth: function ({
      user,
      config,
    }: {
      user: User | undefined;
      config: Config | undefined;
    }) {
      setValue({
        ...value,
        status: authStatus.Ready,
        user,
        config,
        error: undefined,
      });
    },
    cleanAuth,
    status: authStatus.Loading,
  });

  const initializeAuth = async (): Promise<AuthProviderValue> => {
    const COOKIE_RTOKEN = getCookie(COOKIE_REFRESH_TOKEN);
    if (COOKIE_RTOKEN) {
      try {
        const getUser = await refreshToken(COOKIE_RTOKEN);
        if (getUser.status === 200) {
          setCookie(COOKIE_TOKEN, getUser?.user?.token || "");
          setCookie(COOKIE_REFRESH_TOKEN, getUser?.user?.refreshToken || "");
          const TOKEN = getCookie(COOKIE_TOKEN);
          if (TOKEN) {
            const config = await getConfig(TOKEN);
            if (config?.status === 200) {
              return {
                ...value,
                status: authStatus.Ready,
                user: getUser?.user || undefined,
                config: config?.doc || undefined,
              };
            } else {
              return {
                ...value,
                status: authStatus.Ready,
              };
            }
          }
        }
        return {
          ...value,
          status: authStatus.Ready,
        };
      } catch (error) {
        destroyStorageAndCookies();
        return {
          ...value,
          status: authStatus.Ready,
        };
      }
    }
    return {
      ...value,
      status: authStatus.Ready,
    };
  };

  React.useEffect(() => {
    initializeAuth()
      .then((auth) => {
        setValue(auth);
      })
      .catch((error) => {
        setValue({
          ...value,
          status: authStatus.Error,
          error,
        });
      });
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, authStatus, useAuthContext };
