/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import Swal from "sweetalert2";
import { COOKIE_TOKEN } from "../../utils/constants";
import { getCookie } from "../../utils/cookies";
import { getConfig, putConfig } from "./helpers";
import { Config } from "./index";
import { ConfigResponse } from "./types";

export enum Status {
  Loading,
  Ready,
  Updating,
  Error,
}

export const useConfigs = () => {
  const [settingInfo, setSettingInfo] = React.useState<Config | null>(null);
  const [status, setStatus] = React.useState(Status.Loading);

  async function getSettingsConfig(): Promise<ConfigResponse> {
    const token = getCookie(COOKIE_TOKEN) || "";
    return await getConfig(token)
      .then((response) => {
        if (response?.status === 200) {
          setSettingInfo(response?.doc || null);
          // sessionStorage.setItem(
          //   BUSINESS_CONFIG,
          //   JSON.stringify(response?.doc)
          // );
        }
        setStatus(Status.Ready);
        return response;
      })
      .catch((error) => {
        setStatus(Status.Ready);
        return error;
      });
  }

  async function updateConfig(
    id: string,
    config: any
  ): Promise<ConfigResponse> {
    setStatus(Status.Updating);
    const token = getCookie(COOKIE_TOKEN) || "";
    return putConfig(token, id, config)
      .then((response) => {
        if (response?.status === 200) {
          Swal.fire({
            icon: "success",
            title: "¡Actualización Exitosa!",
            text: "Configuración actualizada exitosamente",
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Algo salió mal!",
            text: response?.message || "",
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        }
        setStatus(Status.Ready);
        return response;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Algo salió mal!",
          text: "Ocurrió un error inesperado",
          timer: 2000,
          confirmButtonColor: "#2e2868",
        });
        setStatus(Status.Ready);
        return error;
      });
  }

  return {
    settingInfo,
    getSettingsConfig,
    updateConfig,
    status,
  };
};
