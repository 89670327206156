import React from "react";

import { Route } from "react-router-dom";

export const PrivateRoute = ({
  path = "/",
  isAuthenticated = false,
  component: Component,
  ...rest
}: any) => {
  return (
    <Route
      path={path}
      {...rest}
      component={(props: any) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Component to="/auth/login" />
        )
      }
    />
  );
};
