import * as React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilWallet,
  cilUser,
  cilPeople,
  cilScreenDesktop,
  cilCommentBubble,
  cilBank,
  cilStorage,
  cilLibrary,
  cilFolder,
  cilApplications,
  cilSettings,
  cilWallpaper,
  cilChartPie,
  cibJson,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";
import { useAuthContext } from "./context/AuthContext";

enum Roles {
  USER = "user",
  ADMIN = "admin",
  MODERATOR = "moderator",
}

const NavRoutes = (): any[] => {
  const { user } = useAuthContext();

  const role = user?.role?.name;

  const isAdmin: boolean = role === Roles.ADMIN;
  const isModerator: boolean = role === Roles.MODERATOR;

  const routes = [
    isAdmin || isModerator
      ? {
          component: CNavTitle,
          name: "Sistema Administrativo",
        }
      : undefined,
    isAdmin || isModerator
      ? {
          component: CNavItem,
          name: "Escritorio",
          to: "/home",
          icon: <CIcon icon={cilScreenDesktop} customClassName="nav-icon" />,
        }
      : undefined,
    isModerator
      ? {
          component: CNavGroup,
          name: "Accesos",
          icon: <CIcon icon={cilStorage} customClassName="nav-icon" />,
          items: [
            // {
            //   component: CNavItem,
            //   name: "Roles",
            //   to: "/roles",
            //   icon: <CIcon icon={cilWallet} customClassName="nav-icon" />,
            // },
            {
              component: CNavItem,
              name: "Usuarios",
              to: "/usuarios",
              icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
            },
          ],
        }
      : undefined,
    isModerator || isAdmin
      ? {
          component: CNavGroup,
          name: "Colaboradores",
          icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
          items: [
            {
              component: CNavItem,
              name: "Médicos",
              to: "/medicos",
              icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
            },
            isModerator
              ? {
                  component: CNavItem,
                  name: "Empleados",
                  to: "/empleados",
                  icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
                }
              : undefined,
          ],
        }
      : undefined,
    isModerator
      ? {
          component: CNavGroup,
          name: "Negocio",
          icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
          items: [
            {
              component: CNavItem,
              name: "Tipos de Documento",
              to: "/tipos-documento",
              icon: <CIcon icon={cilWallet} customClassName="nav-icon" />,
            },
            // {
            //   component: CNavItem,
            //   name: "Cargos",
            //   to: "/cargos",
            //   icon: <CIcon icon={cilWallet} customClassName="nav-icon" />,
            // },
            {
              component: CNavItem,
              name: "Centros",
              to: "/centros",
              icon: <CIcon icon={cilBank} customClassName="nav-icon" />,
            },
          ],
        }
      : undefined,
    isAdmin || isModerator
      ? {
          component: CNavItem,
          name: "Pacientes",
          to: "/pacientes",
          icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        }
      : undefined,
    isAdmin || isModerator
      ? {
          component: CNavGroup,
          name: "Archivos",
          icon: <CIcon icon={cilFolder} customClassName="nav-icon" />,
          items: [
            {
              component: CNavItem,
              name: "Órdenes",
              to: "/ordenes",
              icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
            },
            {
              component: CNavItem,
              name: "Exámenes",
              to: "/examenes",
              icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
            },
            // {
            //   component: CNavItem,
            //   name: "Historias",
            //   to: "/historias",
            //   icon: <CIcon icon={cilHistory} customClassName="nav-icon" />,
            // },
          ],
        }
      : undefined,
    isModerator || isAdmin
      ? {
          component: CNavGroup,
          name: "Reportes",
          icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
          items: [
            {
              component: CNavItem,
              name: "Exámenes por médico",
              to: "/reportes/examenes-medico",
              icon: <CIcon icon={cilWallpaper} customClassName="nav-icon" />,
            },
          ],
        }
      : undefined,

    isModerator || isAdmin
      ? {
          component: CNavGroup,
          name: "Utilidades",
          icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
          items: [
            {
              component: CNavItem,
              name: "Unificar PDFs",
              to: "/utilidades",
              icon: <CIcon icon={cibJson} customClassName="nav-icon" />,
            },

            isModerator
              ? {
                  component: CNavItem,
                  name: "Configuración",
                  to: "/configuracion",
                  icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
                }
              : undefined,
          ],
        }
      : undefined,

    isAdmin || isModerator
      ? {
          component: CNavItem,
          name: "Ayuda",
          to: "/ayuda",
          icon: <CIcon icon={cilCommentBubble} customClassName="nav-icon" />,
        }
      : undefined,
  ];

  let cleanRoutes = routes.filter((route) => route !== undefined);

  let cleanSubRoutes = cleanRoutes.map((route) => {
    if (route?.items) {
      if (route?.items?.length > 0) {
        let newRouter = { ...route };
        newRouter.items = route?.items.filter((item) => item !== undefined);
        return newRouter;
      }
    } else {
      return route;
    }
  });

  return cleanSubRoutes;
};

export default NavRoutes;
