import { cilHamburgerMenu } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import Swal from "sweetalert2";

type ImageProps = {
  image?: string;
  imageTitle?: string;
  imageButton?: string;
  updateImage?: any;
};

type ManualProps = {
  manual?: string;
  manualTitle?: string;
};

type LayoutFormProps = {
  title: string;
  className?: string;
  children: React.ReactNode;
  minHeight?: string;
  manual?: ManualProps;
  image?: ImageProps;
};

export const LayoutContainer = ({
  children,
  title,
  minHeight,
  className = "col-12 col-sm-6 col-md-8 my-auto",
  manual,
  image,
}: LayoutFormProps) => {
  return (
    <div className="row my-3">
      <div className="col-lg-12">
        <div
          className="card"
          style={{
            minHeight: minHeight ? minHeight : undefined,
          }}
        >
          <div className="card-header">
            <div className="row">
              <div className={className}>
                <CIcon icon={cilHamburgerMenu} />
                &nbsp;{title}
              </div>
              {manual?.manual ? (
                <div className="ms-auto d-flex my-1 my-md-0 col-12 col-sm-6 col-md-3 col-lg-2 text-end">
                  <button
                    className="btn btn-dark w-100"
                    onClick={() => window.open(manual.manual, "_blank")}
                  >
                    {manual?.manualTitle || "Ver Manual"}
                  </button>
                </div>
              ) : null}
              {image?.image ? (
                <div className="ms-auto d-flex my-1 my-md-0 col-12 col-sm-6 col-md-3 col-lg-2 text-end">
                  <button
                    className="btn btn-dark w-100"
                    onClick={() =>
                      Swal.fire({
                        imageUrl: image?.image,
                        imageHeight: "auto",
                        padding: "20",
                        imageAlt: image?.imageTitle || "Imagen",
                        confirmButtonText: "Cerrar",
                        confirmButtonColor: "#4f5d73",
                        showDenyButton: true,
                        denyButtonText: "Limpiar",
                        denyButtonColor: "#9da5b1",
                      }).then((result) =>
                        result.isDenied ? image?.updateImage() : null
                      )
                    }
                  >
                    {image?.imageButton || "Ver Foto"}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
