import * as React from "react";
import { getCookie } from "../../utils/cookies";
import Swal from "sweetalert2";

import { deleteExam, getExamById, getExams } from "./helpers";
import { Exam } from "./index";
import { BodyParams, PaginateParams, PaginateResponse } from "../types";
import { ExamResponse } from "./types";
import { COOKIE_TOKEN } from "../../utils/constants";

export enum Status {
  Loading,
  Ready,
  Updating,
  Error,
}

export const useExams = () => {
  const [exams, setExams] = React.useState<Exam[]>([]);
  const [examInfo, setExamInfo] = React.useState<Exam | null>(null);
  const [status, setStatus] = React.useState(Status.Loading);
  const [paginateData, setPaginateData] =
    React.useState<PaginateResponse | null>(null);
  const [searchFilter, setSearchFilter] = React.useState<BodyParams>({
    startDate: "",
    endDate: "",
    status: null,
  });

  async function setExamById(id: string): Promise<ExamResponse> {
    setStatus(Status.Loading);
    const token = getCookie(COOKIE_TOKEN) || "";
    return getExamById(token, id)
      .then((response) => {
        if (response?.status === 200) {
          setExamInfo(response?.doc || null);
          setStatus(Status.Ready);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  async function getExamsByFilter(
    { startDate, endDate, status, doctorId }: BodyParams,
    { limit, pageSize }: PaginateParams
  ) {
    const token = getCookie(COOKIE_TOKEN) || "";
    return getExams(
      token,
      { startDate, endDate, status, doctorId },
      { limit, pageSize }
    )
      .then((response: PaginateResponse) => {
        if (response?.status === 200) {
          const { docs: ordersObtained = [] } = response || {};
          setExams(ordersObtained);
          setPaginateData(response);
        } else {
          Swal.fire({
            icon: "error",
            title: "Algo salió mal!",
            text: response?.message || "",
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        }
        setStatus(Status.Ready);
        return response;
      })
      .catch((error) => {
        setStatus(Status.Error);
        return error;
      })
      .finally(() => {
        setSearchFilter({
          ...searchFilter,
          startDate,
          endDate,
          status,
          doctorId,
        });
      });
  }

  async function eliminateExam(id: string): Promise<ExamResponse> {
    setStatus(Status.Updating);
    const token = getCookie(COOKIE_TOKEN) || "";
    return deleteExam(token, id)
      .then((response) => {
        if (response?.status === 204) {
          setExams(exams.filter((order: Exam) => order._id !== id));
          Swal.fire({
            title: "¡Todo salió bien!",
            icon: "success",
            text: `Examen eliminado permanentemente`,
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "¡Algo salió mal!",
            text: response?.message || "",
            timer: 2000,
            confirmButtonColor: "#2e2868",
          });
        }
        setStatus(Status.Ready);
        return response;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Algo salió mal!",
          text: "Ocurrió un error inesperado",
          timer: 2000,
          confirmButtonColor: "#2e2868",
        });
        setStatus(Status.Ready);
        return error;
      });
  }

  function changePage(index: number, limit: number) {
    getExamsByFilter(searchFilter, { limit, pageSize: index });
  }

  function cleanExams() {
    setExams([]);
  }

  return {
    exams,
    setExamById,
    eliminateExam,
    examInfo,
    searchFilter,
    getExamsByFilter,
    paginateData,
    changePage,
    cleanExams,
    status,
  };
};
