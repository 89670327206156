import React from "react";
import { CFooter } from "@coreui/react";

const AppFooter = () => {
  return (
    <CFooter style={{ backgroundColor: "#2a2b36" }}>
      <div className="ms-auto">
        <p className="mx-auto text-light">
          &copy; {new Date().getFullYear()} - Desarrollado por Pablo Urbano
        </p>
      </div>
    </CFooter>
  );
};

export default React.memo(AppFooter);
